<template>
    <fragment>
        <div class="pflbx-product-image">
            <img v-bind:src="mediaUrl" />
        </div>
        <span class="pflbx-name">{{name}} </span>
        <div class="pflbx-product-action">
            <button class="pflbx-button remove" @click="removeProduct" :disabled="canRemove" aria-label="Entfernen"></button>
            <span class="pflbx-amount">{{amount()}}</span>
            <button class="pflbx-button add" @click="addProduct" :disabled="canAdd" aria-label="Hinzufügen"></button>
        </div>
    </fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';

export default {
    name: 'Product',
    components: { Fragment },
    props: ['product', 'productName'],
    data() {
        return {
            iamount: 0 
        }
    },
    computed: {
        name() {
            if(this.productName !== undefined)
                //this.product.name = this.productName;
                return this.productName;
            return this.product.name; 
        },
        canRemove() {
            return this.amount() <= 0;
        },
        canAdd() {
            let price = parseFloat(this.product.calculatedPrice.totalPrice);
            let creditLeft = this.$store.getters.creditLeft;

            return creditLeft < price;    
        },
        mediaUrl() {
            if(this.product.cover !== undefined)
                return this.product.cover.media.url;
            return '';
        }
    },
    methods: {
        removeProduct() {
            //this.amount--;
            this.$store.commit('removeProduct', this.product);    
        },

        addProduct() {
            var payload = {
                product: this.product,
                name: this.productName   
            }
            this.$store.commit('addProduct', payload);
        },
        
        amount() {
            let content = this.$store.getters.content;
            if(content == null || content[this.product.id] === undefined || content[this.product.id] == null)
                return 0;
            return content[this.product.id].amount;
            
            //if(cartContent == null || cartContent === undefined)
            //return 0;
            //return cartContent;
        },
    }


}
</script>

<style lang="scss">
</style>